import React from "react";
import Card from "react-bootstrap/Card";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p>
            As an award-winning graduate from the University of
            Michigan-Dearborn with a BS in Computer Science, I have a strong
            foundation in full-stack development and IT support applications. I
            have experience working with various employers from{" "}
            <b className="purple">Harman International to Comerica Bank</b>.
            <br />
            <br />I am passionate about creating intuitive and secure apps
            solving real, everyday problems, using languages such as
            <i>
              <b className="purple"> JavaScript, Python, and C#. </b>
            </i>
            <br />
            <br />
            Currently, I am an Associate Automation Developer at&nbsp;
            <i>
              <b className="purple">Hastings Mutual Insurance Company </b> where
              I contribute to the design, development, and testing of automation
              solutions for various business processes. I leverage my skills in{" "}
              <b className="purple">UiPath Studio and Python</b> to create and
              maintain automation workflows that help improve efficiency and
              accuracy in the workplace.
            </i>
            <br />
            <br />I am constantly trying to find new ways to make technology
            serve us and make our lives easier. I am improving my skills with{" "}
            <b className="purple">Python</b> and
            <i>
              <b className="purple">
                {" "}
                Computer Vision and Business Automation tools
              </b>
            </i>
            &nbsp; like
            <i>
              <b className="purple"> OpenCV and Power Automate.</b>
            </i>
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
