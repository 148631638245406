import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import speedle from "../../Assets/Projects/speedle-logo.png";
import foodfight from "../../Assets/Projects/food-fight-logo-rounded.png";
import projecthush from "../../Assets/Projects/project-hush-logo-v2.png";
import repsmap from "../../Assets/Projects/interactive-reps-map-logo.png";
import honda from "../../Assets/Projects/honda-inventory-manager.png";
import reportcard from "../../Assets/Projects/report-card-maker-logo.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Noteworthy <strong className="purple">Work </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are some projects I've been working on.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={honda}
              noCode={false}
              title="Honda Dealership Inventory Management System"
              description="The Python tKinter library was used to create a GUI that allows users to manage the inventory of a fictional Honda dealership. The SQLite database stores customer information, vehicle inventory, and sales & invoice data."
              ghLink="https://github.com/mozyn/dealership-inventory"
              demoLink="https://github.com/mozyn/dealership-inventory/blob/main/honda-dealer-demo.gif/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={repsmap}
              noCode={true}
              title="Interactive Non-Profit Volunteer Representatives Map"
              description="Using the amCharts library, I created an interactive map that displays the locations of volunteer representatives for a non-profit organization. The map features key statistics and displays the rep's info when clicked."
              ghLink="https://map.al-ayn.org/"
              demoLink="https://map.al-ayn.org/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={foodfight}
              noCode={false}
              title="FoodFight - Group Meal Decider"
              description="Food Fight is the perfect way to make meal decisions entertaining. Utilizes local storage, a persistent database, a voting algorithm, and a mobile-friendly interface to make the process of deciding on a meal with friends and family fun and easy."
              ghLink="https://github.com/mozyn/food-fight"
              demoLink="https://food-fight.app"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={projecthush}
              noCode={false}
              title="Project Hush - Password Encryptor"
              description="Project Hush uses C# libraries and Windows Form design to encrypt your lengthy passwords using a custom algorithm and stores the result in a QR Code to be retrieved and decrypted later!"
              ghLink="https://github.com/mozyn/Project-Hush"
              demoLink="https://github.com/mozyn/mozyn/blob/561ae7c38cbdb12688344e5fb65cf99fd9b3d3f7/project-hush-demo.gif"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={speedle}
              noCode={true}
              title="Speedle - Word Game"
              description="Speedle is a fast-paced word game that challenges players to create words from a set of letters. The game features a time and a score tracker to give an intense and competitive experience!"
              ghLink="https://www.speedlegame.com"
              demoLink="https://www.speedlegame.com"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={reportcard}
              noCode={false}
              title="Report Card Maker"
              description="Report Card Creation Tool to keep track of courses and calculate GPA developed using C++, user-tested, and maintained to version 3.1. Complete with installation wizard and executable."
              ghLink="https://github.com/mozyn/reportcard-maker"
              demoLink="https://github.com/mozyn/mozyn/blob/main/report_card_demo.gif"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
