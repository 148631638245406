import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import { BsGithub } from "react-icons/bs";
import { FaPlayCircle } from "react-icons/fa";

function ProjectCards(props) {
  return (
    <Card className="project-card-view">
      <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text>{props.description}</Card.Text>
        {"\n"}
        {"\n"}

        {/* Render Demo button if there's a demo link */}
        {props.demoLink && (
          <Button
            variant="primary"
            href={props.demoLink}
            target="_blank"
            style={{ marginLeft: "10px" }}
          >
            <FaPlayCircle />
            &nbsp;
            {"Demo"}
          </Button>
        )}

        {/* Render GitHub button only if noCode is false */}
        {!props.noCode && (
          <Button
            variant="secondary"
            href={props.ghLink} // Assuming you have a prop for GitHub link
            target="_blank"
            style={{ marginLeft: "10px" }}
          >
            <BsGithub />
            &nbsp;
            {"GitHub"}
          </Button>
        )}
      </Card.Body>
    </Card>
  );
}

export default ProjectCards;
